import { Button, Card, Col, Row, Table, message, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPemilih,fetchAllPemilihQuery,fetchPemilihByNIK } from 'redux/features/pemilih';
import DAPIL_TIDAK_SESUAI from './DapilTidakSesuai';
import DOUBLE from './Double';
import NIK_TIDAK_VALID from './ProsesVerifikasi';
import NIK_TIDAK_VALID_SUDAH from './NIKTidakValidSudahDicek';

export const SINKRON = () => {

    return (
        <>
            <NIK_TIDAK_VALID isTambah={false}></NIK_TIDAK_VALID>
            <NIK_TIDAK_VALID_SUDAH isTambah={false}></NIK_TIDAK_VALID_SUDAH>
            <DAPIL_TIDAK_SESUAI></DAPIL_TIDAK_SESUAI>
            <DOUBLE></DOUBLE>
        </>
    )
}


export default withRouter(SINKRON);
