import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter,useLocation } from 'react-router-dom';
import { fetchAllDapilQuery } from 'redux/features/dapil';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const KABUPATEN = () => {

    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const [kecamatan,setKecamatan] = useState([])

    const getData = async (id_kabupaten) => {
        try {
            const kecamatan = await dispatch(fetchAllDapilQuery({
                id_kabupaten: id_kabupaten,
                limit: 30,
                page: 0
            })).unwrap()
            console.log(kecamatan)
            setKecamatan(kecamatan)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        const itemId = location.state?.id_kabupaten
        if (itemId) {
            getData(itemId)
        }
    }, [])

    const tableColumns = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Daftar Kecamatan',
            dataIndex: 'kecamatan',
            render: (_, record) => {
                return (
                    <div onClick={() => {
                        history.push("/app/kecamatan", {
                            id_dapil: record.id_dapil
                        })
                    }}><a>Lihat Kecamatan</a></div>
                )
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            render: (_, record) => {
                return (
                    <a onClick={() => {
                        history.push("/app/detail-dapil", {
                            status: "DETAIL",
                            id_kabupaten:location.state?.id_kabupaten,
                            id_dapil: record._id
                        })
                    }}>Edit</a>
                )
            },
        }
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Total {kecamatan.length} Dapil </h2>
                    <p>Semua data yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={kecamatan}
                            rowKey='id'
                            Row={5}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            status: "ADD",
                            id_kabupaten:location.state?.id_kabupaten,
                            pathname: '/app/detail-dapil'
                        })
                    }} block>
                        Tambah Data Dapil
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(KABUPATEN);
