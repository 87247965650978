import { Button, Card, Col, Row, Select, message, Form } from 'antd';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { addMany } from 'redux/features/pemilih'
import Papa from 'papaparse';
import { useDispatch } from 'react-redux';
import { fetchAllCaleg } from 'redux/features/caleg';
import { useEffect, useCallback } from 'react';
import moment from 'moment';
import jwt_decode from 'jwt-decode'

const Option = Select

const CsvUpload = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCaleg, setIdCaleg] = useState('')
    const [daftar_caleg, setDaftarCaleg] = useState([])
    const [baris, setBaris] = useState();

    const getCaleg = useCallback(async () => {
        try {
            const response = await dispatch(fetchAllCaleg()).unwrap()
            setDaftarCaleg(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            complete: (result) => {
                console.log('Parsed Result:', result);
                setBaris(result.data.length)
                let token = localStorage.getItem("token")
                let user = jwt_decode(token)
                setData(result.data.map(pemilih => {
                    return {
                        ...pemilih,
                        caleg: selectedCaleg,
                        submitted_by: user._id,
                        tanggal_input: moment().format("DD-MM-YYYY"),
                    };
                }))
            },
            header: true
        });
    };

    const uploadData = async (upload) => {
        try {
            setLoading(true)
            const dataString = JSON.stringify(upload)
            const response = await dispatch(addMany({
                data: dataString,
            })).unwrap()
            message.success('Berhasil menambahkan data!')
            history.push("/app/dashboard")
        } catch (error) {
            setLoading(false)
            console.error('Error uploading data', error);
            message.error('Gagal menambahkan data. Coba lagi! Pastikan format sesuai')
        }
    };

    useEffect(() => {
        getCaleg()
    }, [])

    return (
        <Col sm={24} md={24} xl={24} >
            <Card style={{ width: "100%" }}>
                <p>Update dan tambah data pemilih sesuai dengan dapil yang dituju. <a target='_blank' href="https://docs.google.com/spreadsheets/d/1_13yfd792XswNJ0MMnihBow5puTbtu-StDVLvZT74jU/edit#gid=0">format upload bulk</a> download as CSV</p>
                
                <Form.Item
                    required={true}
                    label="Caleg"
                    name="caleg"
                >
                    <Select onSelect={(value) => {
                        setIdCaleg(value)
                    }}>
                        {daftar_caleg.map(data => {
                            return (
                                <Option value={data._id}>
                                    {data.name}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                {selectedCaleg && (<input type="file" accept=".csv" onChange={handleFileUpload} />)}
                <br />
                <h5>Data Calon Pemilih: {baris}</h5>
                <br />
                <Button loading={loading} onClick={() => { uploadData(data) }} style={{ width: "100%" }} type='primary'>Upload</Button>
            </Card>
        </Col>
    );
};

export default CsvUpload;