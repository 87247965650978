import { Col, Row, message, Form, Input, Button } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BarChart } from 'components/custom-chart/BarChart';
import { PieChart } from 'components/custom-chart/PieChart';
import { fetchAllCaleg } from 'redux/features/caleg';
import { fetchAllPemilih, fetchAllPemilihCount } from 'redux/features/pemilih';

export const AnnualStatisticData = [
    {
        title: 'Total Pemilih',
        value: 'Loading...',
    },
    {
        title: 'Pemilih Terverifikasi',
        value: 'Loading...',
    },
    {
        title: 'Pemilih Belum Terverifikasi',
        value: 'Loading...',
    }
]

export const GroupChart = (props) => {

    const dispatch = useDispatch()
    const [statistic, setStatistic] = useState({
        total: 0,
        terverifikasi: 0,
        tidak_terverifikasi: 0
    })

    const [dashboard, setDashboard] = useState({
        total_pemilih_kabupaten: {
            data: [],
            labels: []
        },
        total_admin_pemilih: {
            data: [],
            labels: []
        },
        pertumbuhan_7_hari: {
            data: [],
            labels: []
        },
        jumlah_terverifikasi: {
            data: [],
            labels: []
        },
    })

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllCaleg()).unwrap()
            await dispatch(fetchAllPemilih()).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const getDashboard = async () => {
        const response = await dispatch(fetchAllPemilihCount()).unwrap()
        const { terverifikasi, tidak_terverifikasi, total } = response[0]
        setStatistic({
            ...statistic,
            terverifikasi,
            tidak_terverifikasi,
            total
        })
    }

    useEffect(() => {
        console.log(props)
        getData()
        getDashboard()
    }, [])

    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12}>
                {props?.pemilihPerCity.kabupatenPemilih && props?.pemilihPerCity.pemilihPerCityCount && (
                    <BarChart color={'#e5222a'} labels={props?.pemilihPerCity.kabupatenPemilih} data={props?.pemilihPerCity.pemilihPerCityCount} title={"Jumlah Calon Pemilih Per Kabupaten"}></BarChart>
                )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                {props?.pemilihPerCity.kabupatenPemilih && props?.pemilihPerCity.pemilihPerCityCount && (
                    <BarChart color={'#e5222a'} labels={props?.pemilihPerCity.kabupatenPemilih} data={props?.pemilihPerCity.pemilihPerCityCount} title={"Jumlah Calon Pemilih Per Kecamatan"}></BarChart>
                )}
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12}>
                {props?.admin.adminList && props?.admin.pemilihAdminList && (
                    <BarChart color={'#e5222a'} labels={props?.admin.adminList} data={props?.admin.pemilihAdminList} title={"Top 10 Admin Input Terbanyak"}></BarChart>
                )}
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={12}>
                {props?.pemilihPerCity.kabupatenPemilih && props?.pemilihPerCity.pemilihPerCityCount && (
                    <BarChart color={'#e5222a'} labels={props?.pemilihPerCity.kabupatenPemilih} data={props?.pemilihPerCity.pemilihPerCityCount} title={"Jumlah Calon Pemilih Per TPS"}></BarChart>
                )}
            </Col>
            
            {/* <Col xs={24} sm={24} md={12} lg={12}>
                <BarChart color={'#e5222a'} labels={['Hari-1', 'Hari-2', 'Hari-3', 'Hari-4', 'Hari-5', 'Hari-6', 'Hari-7']} data={dashboard?.pertumbuhan_7_hari} title={"Semua Kecamatan di Kabupaten Terpilih"}></BarChart>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={12}>
                <PieChart color={'#e5222a'} labels={['Terverifikasi', 'Tidak  Terverifikasi']} data={[statistic?.terverifikasi, statistic?.tidak_terverifikasi]} title={"Jumlah Calon Pemilih Terverifikasi"}></PieChart>
            </Col>
        </Row>
    )
}


export default withRouter(GroupChart);
