import React, {  Suspense } from "react";
import { Switch,Redirect} from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import DASHBOARD from "./dashboard"
import PEMILIH from "./pemilih"
import SEMUA_PEMILIH from "./semua-pemilih"
import KELURAHAN from "./kelurahan"
import KECAMATAN from "./kecamatan"
import KABUPATEN from "./kabupaten"
import TPS from "./tps"
import CALEG from "./caleg"
import DAPIL from "./dapil"
import ANALISA from "./analisa"
import PROVINSI from "./provinsi"
import PENGGUNA from "./pengguna"
import SEMUA_CALEG from "./semua-caleg"
import SINKRONISASI from "./sinkronisasi"

// Detail
import DETAIL_PEMILIH from "./detail-pemilih"
import DETAIL_CALEG from "./detail-caleg"
import DETAIL_PENGGUNA from "./detail-pengguna"
import DETAIL_KABUPATEN from "./detail-kabupaten"
import DETAIL_KECAMATAN from "./detail-kecamatan"
import DETAIL_KELURAHAN from "./detail-kelurahan"
import DETAIL_TPS from "./detail-tps"
import DETAIL_DAPIL from "./detail-dapil"
import DETAIL_SINKRONISASI from "./detail-sinkronisasi"
import DETAIL_SUARA_CALEG from "./detail-suara-caleg"
import TAMBAH_PEMILIH from "./tambah-pemilih"

import SETTINGS from "./settings"

export const AppViews = ({match}) => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${strings.navigation.path.dashboard}`} component={DASHBOARD} />
        <PrivateRoute path={`${strings.navigation.path.pemilih}`} component={PEMILIH} />
        <PrivateRoute path={`${strings.navigation.path.provinsi}`} component={PROVINSI} />
        <PrivateRoute path={`${strings.navigation.path.kabupaten}`} component={KABUPATEN} />
        <PrivateRoute path={`${strings.navigation.path.caleg}`} component={CALEG} />
        <PrivateRoute path={`${strings.navigation.path.tps}`} component={TPS} />
        <PrivateRoute path={`${strings.navigation.path.sinkronisasi}`} component={SINKRONISASI} />
        <PrivateRoute path={`${strings.navigation.path.semua_caleg}`} component={SEMUA_CALEG} />
        <PrivateRoute path={`${strings.navigation.path.kelurahan}`} component={KELURAHAN} />
        <PrivateRoute path={`${strings.navigation.path.analisa}`} component={ANALISA} />
        <PrivateRoute path={`${strings.navigation.path.dapil}`} component={DAPIL} />
        <PrivateRoute path={`${strings.navigation.path.kecamatan}`} component={KECAMATAN} />
        <PrivateRoute path={`${strings.navigation.path.pengguna}`} component={PENGGUNA} />
        <PrivateRoute path={`${strings.navigation.path.semua_pemilih}`} component={SEMUA_PEMILIH} />

        {/* DETAIL */}
        <PrivateRoute path={`${strings.navigation.path.detail_pemilih}`} component={DETAIL_PEMILIH} />
        <PrivateRoute path={`${strings.navigation.path.detail_pengguna}`} component={DETAIL_PENGGUNA} />
        <PrivateRoute path={`${strings.navigation.path.detail_caleg}`} component={DETAIL_CALEG} />
        <PrivateRoute path={`${strings.navigation.path.detail_dapil}`} component={DETAIL_DAPIL} />
        <PrivateRoute path={`${strings.navigation.path.detail_suara_caleg}`} component={DETAIL_SUARA_CALEG} />
        <PrivateRoute path={`${strings.navigation.path.detail_sinkronisasi}`} component={DETAIL_SINKRONISASI} />
        <PrivateRoute path={`${strings.navigation.path.detail_kabupaten}`} component={DETAIL_KABUPATEN} />
        <PrivateRoute path={`${strings.navigation.path.detail_kecamatan}`} component={DETAIL_KECAMATAN} />
        <PrivateRoute path={`${strings.navigation.path.detail_kelurahan}`} component={DETAIL_KELURAHAN} />
        <PrivateRoute path={`${strings.navigation.path.detail_tps}`} component={DETAIL_TPS} />
        <PrivateRoute path={`${strings.navigation.path.tambah_pemilih}`} component={TAMBAH_PEMILIH} />
        <PrivateRoute path={`${strings.navigation.path.settings}`} component={SETTINGS} />

        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
