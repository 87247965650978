import { Col, Spin, message, Modal, Button, Row, Card, Form, Input } from 'antd';
import React, { useState } from "react";
import { Select } from 'antd';
import jwt_decode from 'jwt-decode'
import { useEffect, useCallback } from 'react';
import { addPemilihVerifikasi, updatePemilih, fetchOnePemilih, deletePemilih } from 'redux/features/pemilih';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchAllKabupaten, fetchAllKabupatenQuery } from 'redux/features/kabupaten';
import { fetchAllKecamatanQuery } from 'redux/features/kecamatan';
import { fetchAllKelurahanQuery } from 'redux/features/kelurahan';
import { fetchAllCaleg } from 'redux/features/caleg';

const Option = Select

export const DETAIL_TERVERIFIKASI = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [jabatan, setJabatan] = useState('Belum Ditentukan')
  const [tipe, setTipe] = useState('Belum Terverifikasi')
  const [messageVerif, setMessageVerif] = useState(null)
  const [isLoadingSubmiting, setIsLoadingSubmitting] = useState(false)
  const [id_kecamatan, setIdKecamatan] = useState(null)
  const [id_kelurahan, setIdKelurahan] = useState(null)
  const [tps, setTPS] = useState(null)
  const [nama, setNama] = useState(null)
  const [nik, setNik] = useState(null)
  const [kelurahan, setKelurahan] = useState(null)
  const [kecamatan, setKecamatan] = useState(null)
  const [kabupaten, setKabupaten] = useState(null)
  const [id_caleg, setIdCaleg] = useState(null)
  const [daftar_kabupaten, setDaftarKabupaten] = useState([])
  const [daftar_kecamatan, setDaftarKecamatan] = useState([])
  const [daftar_kelurahan, setDaftarKelurahan] = useState([])
  const [daftar_tps, setDaftarTPS] = useState([])
  const [daftar_caleg, setDaftarCaleg] = useState([])
  const [id, setId] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const {
    list,
    filter: { q: searchTerm },
    loading: {
      query: loadingQuery,
      mutation: loadingMutation
    }
  } = useSelector(state => state.kabupaten || [])

  const getData = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllKabupatenQuery(0, 17)).unwrap()
      setDaftarKabupaten(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getCaleg = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllCaleg()).unwrap()
      setDaftarCaleg(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getKecamatan = async (value) => {
    try {
      const response = await dispatch(fetchAllKecamatanQuery({
        limit: 100,
        page: 0,
        id_kabupaten: value
      })).unwrap()
      setDaftarKecamatan(response)
      setIdKecamatan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getKelurahan = async (value) => {
    try {
      const response = await dispatch(fetchAllKelurahanQuery({
        limit: 100,
        page: 0,
        id_kecamatan: value
      })).unwrap()
      setDaftarKelurahan(response)
      setIdKelurahan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getDataById = async (id) => {
    try {
      await dispatch(fetchAllKabupaten())
      const data = await dispatch(fetchOnePemilih(id)).unwrap()
      form.setFieldsValue(data[0])
      setJabatan(data[0].jabatan)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      setIsLoadingSubmitting(true)
      let token = localStorage.getItem("token")
      let user = jwt_decode(token)
      const response = await dispatch(addPemilihVerifikasi({
        ...values,
        submitted_by: user._id,
      }))
      setIsModalOpen(true)
      setNama(response?.payload?.name)
      setKelurahan(response?.payload?.kelurahan)
      setKecamatan(response?.payload?.kecamatan)
      setKabupaten(response?.payload?.kabupaten)
      setTPS(response?.payload?.tps)

      if (response?.payload?.msg === "Error, pemilih sudah ada!" || response?.payload?.msg === "Error, NIK tidak valid!" || response?.payload?.msg === "Tidak termasuk di Dapil pilihan! Coba tambahkan jika memang termasuk kabupaten Dapil") {
        message.error(response?.payload?.msg)

        setIsLoadingSubmitting(false)
      } else {
        message.success("Berhasil dibuat silahkan tunggu hasil review!")
        // history.push("/app/pemilih")
        setIsLoadingSubmitting(false)
      }
    } catch (err) {
      message.error("Ada yang salah! Pemilih sudah terdaftar atau NIK tidak valid")
      setIsLoadingSubmitting(false)
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updatePemilih({
        ...values,
        jabatan,
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deletePemilih(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/caleg")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    getData()
    getCaleg()
    if (itemId) {
      setId(itemId)
      getDataById(itemId)
    }
  }, [])

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} span={8}>
        <Card>
          <h4>Terverifikasi Otomatis</h4>
          <p>Upload data dan otomatis diverifikasi melalui DPT Online</p>
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >

            <Form.Item
              label="NIK"
              name="nik"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Caleg"
              name="caleg"
            >
              <Select onSelect={(value) => {
                setIdCaleg(value)
              }}>
                {daftar_caleg.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" disabled={isLoadingSubmiting} htmlType="submit" style={{ width: "100%" }}>
                {isLoadingSubmiting ? <><Spin></Spin> Loading...</> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Card>
          <h5>Hasil Verifikasi : {messageVerif}</h5>
          <p>Nama: {nama}</p>
          <p>TPS: {tps}</p>
        </Card>
      </Col>

    </>
  )
}

export default DETAIL_TERVERIFIKASI