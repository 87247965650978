import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllKecamatan = createAsyncThunk(
    'Kecamatan/fetchAllKecamatan',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.KECAMATAN)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllKecamatanQuery = createAsyncThunk(
    'Kecamatan/fetchAllKecamatan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KECAMATAN}?kabupaten=${payload.kabupaten}`)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const fetchKecamatanByJenis = createAsyncThunk(
    'Kecamatan/fetchKecamatanByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KECAMATAN}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addKecamatan = createAsyncThunk(
    'Kecamatan/addKecamatan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.KECAMATAN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateKecamatan = createAsyncThunk(
    'Kecamatan/updateKecamatan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.KECAMATAN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneKecamatan = createAsyncThunk(
    'Kecamatan/fetchOneKecamatan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KECAMATAN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteKecamatan = createAsyncThunk(
    'Kecamatan/deleteKecamatan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.KECAMATAN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const KecamatanSlice = createSlice({
    name: 'Kecamatan',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllKecamatan.pending, startLoadingQuery)
            .addCase(fetchAllKecamatan.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllKecamatan.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneKecamatan.pending, startLoadingQuery)
            .addCase(fetchOneKecamatan.rejected, stopLoadingQuery)
            .addCase(fetchOneKecamatan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateKecamatan.pending, startLoadingQuery)
            .addCase(updateKecamatan.rejected, stopLoadingQuery)
            .addCase(updateKecamatan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchKecamatanByJenis.pending, startLoadingQuery)
            .addCase(fetchKecamatanByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchKecamatanByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteKecamatan.pending, startLoadingMutation)
            .addCase(deleteKecamatan.fulfilled, stopLoadingMutation)
            .addCase(deleteKecamatan.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = KecamatanSlice.actions;

export default KecamatanSlice.reducer;