import { strings } from 'res';
import { RoutesIcon,InsightIcon,OutletIcon,IntegrationIcon, CommandCenterIcon, ReportIcon, DashboardIcon, SettingIcon, SLAIcon, DeliveryIcon, OrderIcon  } from "../assets/svg/icon";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Analisa",
    //   path: strings.navigation.path.analisa,
    //   title: "Analisa",
    //   icon: IntegrationIcon,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Data Pemilih",
      path: strings.navigation.path.pemilih,
      title: "Data Pemilih",
      icon: DeliveryIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Cek Pemilih",
      path: strings.navigation.path.semua_pemilih,
      title: "Cek Pemilih",
      icon: IntegrationIcon,
      breadcrumb: false,
      submenu: []
    },
    // Gerindra Only
    {
      key: "Calon Legislatif",
      path: strings.navigation.path.semua_caleg,
      title: "Calon Legislatif",
      icon: RoutesIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Sinkronisasi",
      path: strings.navigation.path.sinkronisasi,
      title: "Sinkronisasi",
      icon: OutletIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Kabupaten",
      path: strings.navigation.path.kabupaten,
      title: "Kabupaten",
      icon: InsightIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengguna",
      path: strings.navigation.path.pengguna,
      title: "Pengguna",
      icon: CommandCenterIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;