import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllKabupatenQuery } from 'redux/features/kabupaten';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const KABUPATEN = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {
        list,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.kabupaten || [])

    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllKabupatenQuery({
                limit: 100
            })).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id_kabupaten',
        //     key: "id_kabupaten"
        // },
        // {
        //     title: 'Kode',
        //     dataIndex: '_id',
        //     key: '_id',
        // },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Calon Legislatif',
            dataIndex: 'calon',
            render: (_, record) => {
                return (
                    <div onClick={() => {
                        history.push("/app/semua-caleg", {
                            status: "DETAIL",
                            id_kabupaten: record.id_kabupaten
                        })
                    }}><a>Lihat Semua Caleg</a></div>
                )
            },
        }
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Daerah Pemilihan Berdasarkan Kabupaten</h2>
                    <p>Semua surat yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Table
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            Row={10}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            status: "ADD",
                            pathname: "/app/detail-kabupaten",
                            provinsi_id:16,
                        })
                    }} block>
                        Tambah Data
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(KABUPATEN);
