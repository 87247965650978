import { Button, Card, Col, Row, Table, message, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPemilih,fetchAllPemilihQuery,fetchPemilihByNIK } from 'redux/features/pemilih';
import { fetchAllByTanggalFilter } from 'redux/features/provinsi';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const PEMILIH = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [list,setList] = useState([])

    const [searchValue,setSearchValue] = useState('')


    const getData = useCallback(async () => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                tanggal_input:moment().format("DD-MM-YYYY")
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div>{record?.name}</div>
                )
             }
        },
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: 'nik',
            render: (_, record) => {
                return (
                    <div>{record?.nik}</div>
                )
             }
        },
        {
            title: 'Kelurahan',
            dataIndex: 'kelurahan',
            key: 'kelurahan',
            render: (_, record) => {
                return (
                    <div>{record?.kelurahan?.name}</div>
                )
             }
        },
        {
            title: 'Kecamatan',
            dataIndex: 'kecamatan',
            key: 'kecamatan',
            render: (_, record) => {
                return (
                    <div>{record.kecamatan?.name}</div>
                )
             },
        },
        {
            title: 'Kabupaten/Kota',
            dataIndex: 'kabupaten',
            key: 'kabupaten',
            render: (_, record) => {
                return (
                    <div>{record.kabupaten?.name}</div>
                )
             },
        },
        {
            title: 'Tanggal Input',
            dataIndex: 'tanggal_input',
            key: 'tanggal_input',
            render: (_, record) => {
                return (
                    <div>{record?.tanggal_input}</div>
                )
             },
        },
        {
            title: 'TPS',
            dataIndex: 'tps',
            key: 'tps',
            render: (_, record) => {
                return (
                    <div>{record?.tps}</div>
                )
             },
        },
        {
            title: 'Verifikasi DPT',
            dataIndex: 'tps',
            render: (_, record) => {
               if(record?.isVerified){
                return (
                    <div style={{color:"green"}} >Terverifikasi</div>
                )
               }else{
                return (
                    <div style={{color:"red"}} >Belum Terverifikasi</div>
                )
               }
            },
        },
        {
            title: 'Calon Legislatif',
            dataIndex: 'caleg',
            key:'caleg',
            render:(_,record)=>{
                return(
                    <>{record.caleg ?  <div style={{color:'green'}}><b>{record.caleg?.name}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Diinput Oleh',
            dataIndex: 'submitted_by',
            key:'submitted_by',
            render:(_,record)=>{
                return(
                    <>{record.submitted_by ?  <div style={{color:'green'}}><b>{record.submitted_by?.username}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            render: (_, record) => {
                return (
                    <a onClick={()=>{
                        history.push("/app/detail-pemilih",{
                            status:"DETAIL",
                            id:record._id
                        })
                    }}>Lihat</a>
                )
            },
        }
    ];

    const onSearch = (value) => {
        setSearchValue(value.target.value)
    };

    const search = async (searchValue) =>{
        try {
            if(searchValue !== ''){
                await dispatch(fetchPemilihByNIK(searchValue)).unwrap()
            }else{
                await dispatch(fetchAllPemilih()).unwrap()
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Daftar Calon Pemilih Ditambahkan Tanggal {moment().format("DD-MM-YYYY")}</h2>
                    <p>Semua surat yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                    <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan NIK"
                                onChange={onSearch}
                                style={{
                                    width: "92%",
                                    marginRight: "2%"
                                }}
                            />
                             <Button type="primary" onClick={() => {search(searchValue)}}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            scroll={{ x: 1300 }}
                            Row={5}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/tambah-pemilih'
                        })
                    }} block>
                        Tambah Data Pemilih
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PEMILIH);
