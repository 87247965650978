import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/features/theme'
import authReducer from 'redux/features/auth'
import pemilihReducer from 'redux/features/pemilih'
import penggunaReducer from 'redux/features/pengguna'
import kabupatenReducer from 'redux/features/kabupaten'
import provinsiReducer from 'redux/features/provinsi'
import tpsReducer from 'redux/features/tps'
import calegReducer from 'redux/features/caleg'
import kelurahanReducer from 'redux/features/kelurahan'
import kecamatanReducer from 'redux/features/kecamatan'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    caleg:calegReducer,
    pemilih:pemilihReducer,
    provinsi:provinsiReducer,
    kabupaten:kabupatenReducer,
    kecamatan:kecamatanReducer,
    kelurahan:kelurahanReducer,
    pengguna:penggunaReducer,
    tps:tpsReducer,
    provinsi:provinsiReducer
  }
});

export default store;

