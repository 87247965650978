import { Button, Card, Col, Row, Table, message, Input, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPemilih,fetchAllPemilihQuery,fetchPemilihByNIK, verifPemilih } from 'redux/features/pemilih';

export const PEMILIH = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [loadingVerif,setLoadingVerif] = useState(false)
    const [verifId,setVerifId] = useState('')
    const [list,setList] = useState([])
    const [searchValue,setSearchValue] = useState('')

    const getDataTidakVerif = async () => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                isVerified:false,
                isChecked:false,
                isNIKTidakValid:false,
                tanggal_input:props.tanggal_input
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getData = async () => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                tanggal_input:props?.tanggal_input
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getDataTervirifikasi = async () => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                isVerified:true,
                tanggal_input:props.tanggal_input
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        if(props.terverifikasi){
            getDataTervirifikasi()
        }else if(props.tanggal_tampil){
            getData()
        }else{
            getDataTidakVerif()
        }
    }, [])

    const tableColumns = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div>{record?.name ? record?.name : 'Proses Verifikasi'}</div>
                )
             }
        },
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: 'nik',
            render: (_, record) => {
                return (
                    <div>{record?.nik ? record?.nik : 'Proses Verifikasi'}</div>
                )
             }
        },
        {
            title: 'Kelurahan',
            dataIndex: 'kelurahan',
            key: 'kelurahan',
            render: (_, record) => {
                return (
                    <div>{record?.kelurahan?.name ?record?.kelurahan?.name : 'Proses Verifikasi'}</div>
                )
             }
        },
        {
            title: 'Kecamatan',
            dataIndex: 'kecamatan',
            key: 'kecamatan',
            render: (_, record) => {
                return (
                    <div>{record.kecamatan?.name ? record.kecamatan?.name : 'Proses Verifikasi'}</div>
                )
             },
        },
        {
            title: 'Kabupaten/Kota',
            dataIndex: 'kabupaten',
            key: 'kabupaten',
            render: (_, record) => {
                return (
                    <div>{record.kabupaten?.name ? record.kabupaten?.name : 'Proses Verifikasi'}</div>
                )
             },
        },
        {
            title: 'Tanggal Input',
            dataIndex: 'tanggal_input',
            key: 'tanggal_input',
            render: (_, record) => {
                return (
                    <div>{record?.tanggal_input ? record?.tanggal_input : 'Proses Verifikasi'}</div>
                )
             },
        },
        {
            title: 'TPS',
            dataIndex: 'tps',
            key: 'tps',
            render: (_, record) => {
                return (
                    <div>{record?.tps ? record?.tps : 'Proses Verifikasi'}</div>
                )
             },
        },
        {
            title: 'Verifikasi DPT',
            dataIndex: 'tps',
            render: (_, record) => {
               if(record?.isVerified){
                return (
                    <div style={{color:"green"}} ><b>Terverifikasi</b></div>
                )
               }else{
                if(!record?.isTidakValid){
                    return (
                        <div style={{color:"red"}} > <Spin></Spin>&nbsp;Verifying</div>
                    )
                }else{
                    return (
                        <div style={{color:"red"}} ><b>Data Tidak Valid</b></div>
                    )
                }
                // return (
                //     <div  style={{color:"red"}} onClick={async (row)=>{
                //       try{
                //         setLoadingVerif(true)
                //         setVerifId(record._id)
                //         await dispatch(verifPemilih(record._id))
                //         message.success("Berhasil Diverifikasi")
                //         await getDataTidakVerif()
                //         setLoadingVerif(false)
                //       }catch(err){
                //         message.error(err.message)
                //         setLoadingVerif(false)
                //       }
                //     }} >
                //         <Button loading={loadingVerif && record._id === verifId} type='primary' style={{color:"#FFFFFF"}}>Verifikasi Sekarang</Button>
                //     </div>
                // )
               }
            },
        },
        {
            title: 'Calon Legislatif',
            dataIndex: 'caleg',
            key:'caleg',
            render:(_,record)=>{
                return(
                    <>{record.caleg ?  <div style={{color:'green'}}><b>{record.caleg?.name}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Diinput Oleh',
            dataIndex: 'submitted_by',
            key:'submitted_by',
            render:(_,record)=>{
                return(
                    <>{record.submitted_by ?  <div style={{color:'green'}}><b>{record.submitted_by?.username}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Info',
            dataIndex: 'info',
            key:'info',
            render:(_,record)=>{
                return(
                    <>{record.info ?  <div style={{color:'green'}}><b>{record.info}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            render: (_, record) => {
                return (
                    <a onClick={()=>{
                        history.push("/app/detail-pemilih",{
                            status:"DETAIL",
                            id:record._id
                        })
                    }}>Lihat</a>
                )
            },
        }
    ];

    const onSearch = (value) => {
        setSearchValue(value.target.value)
    };

    const search = async (searchValue) =>{
        try {
            if(searchValue !== ''){
                const response = await dispatch(fetchPemilihByNIK(searchValue)).unwrap()
                setList(response)
            }else{
                const response = await dispatch(fetchAllPemilih()).unwrap()
                setList(response)
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>{props.title ? props.title : 'Semua Calon Pemilih'}</h2>
                    <p>{props.desc}</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                    <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan NIK"
                                onChange={onSearch}
                                style={{
                                    width: "92%",
                                    marginRight: "2%"
                                }}
                            />
                             <Button type="primary" onClick={() => {search(searchValue)}}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            scroll={{ x: 1300 }}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            {props.isTambah && (<Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/tambah-pemilih'
                        })
                    }} block>
                        Tambah Data Pemilih
                    </Button>
                </Col>
            </Row>)}
        </>
    )
}


export default withRouter(PEMILIH);
