import React from 'react';
import { Card } from 'antd'
import { Doughnut } from 'react-chartjs-2';

export const data = {
  labels: ['PLAJU', 'SEBERANG ULU SATU'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export function KecamatanChart() {
  return <Card>
    <h4>2 Kecamatan Terbanyak</h4>
    <Doughnut height={120} data={data} />
  </Card>
}
