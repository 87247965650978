import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllTicket = createAsyncThunk(
	'ticket/fetchAllTicket',
	async (_, { rejectWithValue }) => {
		try {
			const response = await request('get', URLS.TICKET)
			return response.data.ticket
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchOneTicket = createAsyncThunk(
	'ticket/fetchOneTicket',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('get', `${URLS.TICKET}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const updateTicket = createAsyncThunk(
	'ticket/updateTicket',
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await request('patch', `${URLS.TICKET}/${credentials.id}`, credentials)
			return response.data
		} catch (error) {
			console.log(error)
			return rejectWithValue(error)
		}
	}
)

export const deleteTicket = createAsyncThunk(
	'ticket/deleteTicket',
	async (id, { rejectWithValue }) => {
		try {
			const response = await request('delete', `${URLS.TICKET}/${id}`)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	loading: {
		query: false,
		mutation: false
	},
	filter: {
		q: ''
	},
	list: [],
	message:"",
	selected: {},
	selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
	state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const ticketSlice = createSlice({
	name: 'ticket',
	initialState,
	reducers: {
		setAppliedSearchText: (state, action) => {
			state.filter.q = action.payload
		},
		setSelectedRows: (state, action) => {
			state.selectedRows = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAllTicket.pending, startLoadingQuery)
			.addCase(fetchAllTicket.fulfilled, (state, action) => {
				state.list = action.payload
				state.loading.query = false
			})
			.addCase(fetchAllTicket.rejected, stopLoadingQuery)

		builder
			.addCase(fetchOneTicket.pending, startLoadingQuery)
			.addCase(fetchOneTicket.rejected, stopLoadingQuery)
			.addCase(fetchOneTicket.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
			})
		builder
			.addCase(updateTicket.pending, startLoadingQuery)
			.addCase(updateTicket.rejected, stopLoadingQuery)
			.addCase(updateTicket.fulfilled, (state, action) => {
				state.loading.query = false
				state.selected = action.payload
				state.message = "Success"
			})

		builder
			.addCase(deleteTicket.pending, startLoadingMutation)
			.addCase(deleteTicket.fulfilled, stopLoadingMutation)
			.addCase(deleteTicket.rejected, stopLoadingMutation)
	}
});


export const { setSelectedRows, setAppliedSearchText } = ticketSlice.actions;

export default ticketSlice.reducer;