import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllCaleg = createAsyncThunk(
    'Caleg/fetchAllCaleg',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.CALEG)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const fetchCalegByJenis = createAsyncThunk(
    'Caleg/fetchCalegByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.CALEG}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addCaleg = createAsyncThunk(
    'Caleg/addCaleg',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.CALEG, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateCaleg = createAsyncThunk(
    'Caleg/updateCaleg',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.CALEG, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneCaleg = createAsyncThunk(
    'Caleg/fetchOneCaleg',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.CALEG}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteCaleg = createAsyncThunk(
    'Caleg/deleteCaleg',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.CALEG}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const CalegSlice = createSlice({
    name: 'Caleg',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllCaleg.pending, startLoadingQuery)
            .addCase(fetchAllCaleg.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllCaleg.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneCaleg.pending, startLoadingQuery)
            .addCase(fetchOneCaleg.rejected, stopLoadingQuery)
            .addCase(fetchOneCaleg.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateCaleg.pending, startLoadingQuery)
            .addCase(updateCaleg.rejected, stopLoadingQuery)
            .addCase(updateCaleg.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchCalegByJenis.pending, startLoadingQuery)
            .addCase(fetchCalegByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchCalegByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteCaleg.pending, startLoadingMutation)
            .addCase(deleteCaleg.fulfilled, stopLoadingMutation)
            .addCase(deleteCaleg.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = CalegSlice.actions;

export default CalegSlice.reducer;