import { Col, Row, message } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PEMILIH } from '../pemilih';
import { fetchAllCaleg } from 'redux/features/caleg';
import { fetchAllPemilih } from 'redux/features/pemilih';
import { TopCaleg } from './TopCaleg';
import { KecamatanChart } from './KecamatanChart';

export const AnnualStatisticData = [
  {
    title: 'Total Pemilih',
    value: 'Loading...',
  },
  {
    title: 'Pemilih Terverifikasi',
    value: 'Loading...',
  },
  {
    title: 'Pemilih Belum Terverifikasi',
    value: 'Loading...',
  }
]

export const ANALISA = () => {

  const dispatch = useDispatch()

  const dashboard = useSelector(state => state)

  const getData = useCallback(async () => {
    try {
      await dispatch(fetchAllCaleg()).unwrap()
      await dispatch(fetchAllPemilih()).unwrap()
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Visualisasi Data</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi surat surabah'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Data Pemilih`}
                value={dashboard.pemilih?.list?.length}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Data Caleg`}
                value={dashboard.caleg?.list?.length}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Pemilih Terverifikasi`}
                value={0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Kabupaten Pemilih Terbanyak`}
                value={0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Kabupaten`}
                value={17}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Kecamatan`}
                value={"561"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Kelurahan`}
                value={"5332"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Pemilih Terbanyak`}
                value={"568"}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Rata - Rata Pemilih per Calon`}
                value={"3468"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <TopCaleg></TopCaleg>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <KecamatanChart></KecamatanChart>
        </Col>
      </Row>
    </>
  )
}


export default withRouter(ANALISA);
