import { Button, Card, Col, Row, Table, message, Input } from 'antd';
import React, { useEffect, useState } from "react";

import { useHistory, withRouter } from 'react-router-dom';
import Pemilih from './Pemilih';

export const PEMILIH = (props) => {

    return (
        <>
            <Pemilih desc={props.desc} isTambah={false} title="Calon Pemilih Terverifikasi" terverifikasi={true} row={5} ></Pemilih>
        </>
    )
}


export default withRouter(PEMILIH);
