import { Col, Row, message } from 'antd';
import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import { useEffect } from 'react';
import { addKabupaten, updateKabupaten, fetchOneKabupaten, deleteKabupaten } from 'redux/features/kabupaten';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const DETAIL_KABUPATEN = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [jabatan, setJabatan] = useState('Belum Ditentukan')
  const [id, setId] = useState('')

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneKabupaten(id)).unwrap()
      form.setFieldsValue(data[0])
      setId(data[0]._id)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      await dispatch(addKabupaten({
        ...values,
        id_provinsi:16
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/kabupaten")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateKabupaten({
        ...values,
        id: id
      }))
      message.success("Berhasil diupdate silahkan tunggu hasil review!")
      history.push("/app/kabupaten")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deleteKabupaten(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/kabupaten")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    if (itemId) {
      setId(itemId)
      getDataById(itemId)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Detail Kabupaten</h2>
          <p>Update dan tambah data kabupaten sesuai dengan dapil yang dituju.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* <Form.Item

                label="ID"
                name="id_kabupaten"
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                label="Nama"
                name="name"
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  {id ? 'Update' : 'Tambah'} Data
                </Button>
              </Form.Item>

            </Form>
          </Card>
          {id && (
            <Button onClick={() => { deleteData(id) }} type="primary" htmlType="submit" style={{ width: "100%" }}>
              Delete Data
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default DETAIL_KABUPATEN