import { Col, Row, message } from 'antd';
import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import { useEffect } from 'react';
import { addCaleg, updateCaleg, fetchOneCaleg, deleteCaleg } from 'redux/features/caleg';
import { fetchAllKabupaten, fetchAllKabupatenQuery } from 'redux/features/kabupaten';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Option = Select

export const DETAIL_CALEG = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [id_kabupaten, setIdKabupaten] = useState(null)
  const [id_kecamatan, setIdKecamatan] = useState(null)
  const [daftar_kecamatan, setDaftarKecamatan] = useState([])
  const [daftar_kabupaten, setDaftarKabupaten] = useState([])
  const [jabatan, setJabatan] = useState('Belum Ditentukan')
  const [dapil, setDapil] = useState('Belum Ditentukan')
  const [id, setId] = useState('')

  const {
    list,
    filter: { q: searchTerm },
    loading: {
      query: loadingQuery,
      mutation: loadingMutation
    }
  } = useSelector(state => state.kabupaten || [])

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneCaleg(id)).unwrap()
      form.setFieldsValue(data[0])
      setJabatan(data[0].jabatan)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      await dispatch(addCaleg({
        ...values,
        dapil,
        jabatan
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/caleg")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateCaleg({
        ...values,
        jabatan
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/caleg")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deleteCaleg(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/caleg")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    dispatch(fetchAllKabupaten())
    if (itemId) {
      setId(itemId)
      getDataById(itemId)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Detail Caleg</h2>
          <p>Update dan tambah data caleg sesuai dengan dapil yang dituju.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Nama"
                name="name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Nomor Urut"
                name="nomor_urut"
              >
                <Input />
              </Form.Item>

              {/* <Form.Item
                label="Kabupaten"
                name="kabupaten"
              >
                <Select onSelect={(value) => {
                  setIdKabupaten(value)
                }}>
                  {list.map(data => {
                    return (
                      <Option value={data._id}>
                        {data.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {id ? "Update Data" : "Submit Data"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
          {id && (
            <Button onClick={() => { deleteData(id) }} type="primary" htmlType="submit" style={{ width: "100%" }}>
              Delete Data
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default DETAIL_CALEG