const URLS = Object.freeze({
	LOGIN: `/auth/login`,
	REGISTER: `/auth/register`,
	STORE_GOOGLE_ACCOUNT: `/user/auth-google`,
	ACTIVATION: `/send-activation`,
	RESET_PASSWORD: `user/reset-password`,
	RESEND_ACTIVATION: `user/resend-activation`,
	PROFILE: `/users`,
	PEMILIH: `/pemilih`,
	KABUPATEN:'/kabupaten',
	DAPIL:'/dapil',
	KELURAHAN:'/kelurahan',
	PENGGUNA:'/user',
	KECAMATAN: `/kecamatan`,
	PROVINSI: `/provinsi`,
	CALEG:`/caleg`,
	TPS: `/tps`,
})

export default URLS