import { Button, Card, Col, Row, Table, message, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { verifPemilih,fetchAllPemilihQuery,fetchPemilihByNIK,updatePemilih } from 'redux/features/pemilih';
import { fetchAllByTanggalFilter } from 'redux/features/provinsi';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const PROSES_VERIFIKASI = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [loadingVerif,setLoadingVerif] = useState(false)
    const [verifId,setVerifId] = useState('')
    const [list,setList] = useState([])
    const [total,setTotal] = useState('')

    const [searchValue,setSearchValue] = useState('')


    const getData = async () => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                isVerified:false,
                isChecked:true,
                isNIKTidakValid:false,
                limit:5000
            })).unwrap()

            setTotal(response.length)
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: 'nik',
            render: (_, record) => {
                return (
                    <div style={{color:"red"}} >{record?.nik}</div>
                )
             }
        },
        {
            title: 'Cek Manual DPT',
            dataIndex: 'tps',
            render: (_, record) => {
               return(
                <Button style={{color:"#FFFFFF",background:"blue",border:"none"}}>
                    <a target='_blank' href='https://cekdptonline.kpu.go.id/' >Cek Manual</a>
                </Button>
               )
            },
        },
        {
            title: 'Tandai NIK Tidak Valid',
            dataIndex: 'tandaiTidakValid',
            render: (_, record) => {
               if(!record.isNIKTidakValid || false){
                return (
                    <div  style={{color:"red"}} onClick={async (row)=>{
                      try{
                        setLoadingVerif(true)
                        const response = await dispatch(updatePemilih({
                            id:record._id,
                            isVerified:false,
                            isChecked:true,
                            isNIKTidakValid:true
                        })).unwrap()
                        console.log(response)
                        message.success("Berhasil ditandai tidak valid!")
                        getData()
                        setLoadingVerif(false)
                      }catch(err){
                        setLoadingVerif(false)
                      }
                    }} >
                        <Button loading={loadingVerif} type='primary' style={{color:"#FFFFFF",background:"red",border:"none"}}>Tandai Tidak Valid</Button>
                    </div>
                )
               }else{
               return <div style={{color:"red"}} >Sudah ditandai tidak valid!</div>
               }
            }
        },
        {
            title: 'Verifikasi Ulang',
            dataIndex: 'tps',
            render: (_, record) => {
               return (
                    <div  style={{color:"red"}} onClick={async (row)=>{
                      try{
                        setLoadingVerif(true)
                        const response = await dispatch(verifPemilih(record._id)).unwrap()
                        if(response.msg){
                            message.success(response.msg)
                            getData()
                        }else{
                            message.error("Sepertinya NIK tidak valid! Coba cek manual")
                            setLoadingVerif(false)
                        }
                        setLoadingVerif(false)
                      }catch(err){
                        setLoadingVerif(false)
                      }
                    }} >
                        <Button loading={loadingVerif} type='primary' style={{color:"#FFFFFF",background:"green",border:"none"}}>Verifikasi Ulang</Button>
                    </div>
                )
            },
        },
        {
            title: 'Diinput Oleh',
            dataIndex: 'submitted_by',
            key:'submitted_by',
            render:(_,record)=>{
                return(
                    <>{record.submitted_by ?  <div style={{color:'green'}}><b>{record.submitted_by?.username}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            render: (_, record) => {
                return (
                    <a onClick={()=>{
                        history.push("/app/detail-pemilih",{
                            status:"DETAIL",
                            id:record._id
                        })
                    }}>Lihat</a>
                )
            },
        }
    ];

    const onSearch = (value) => {
        setSearchValue(value.target.value)
    };

    const search = async (searchValue) =>{
        try {
            if(searchValue !== ''){
                const response = await dispatch(fetchPemilihByNIK(searchValue)).unwrap()
                setList(response)
            }else{
                getData()
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>NIK Proses Verifikasi Manual (Belum Terdaftar)</h2>
                    <p>Semua data calon yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                    <div style={{ display: "flex" }}>
                            <Input
                                placeholder="Cari Berdasarkan NIK"
                                onChange={onSearch}
                                style={{
                                    width: "92%",
                                    marginRight: "2%"
                                }}
                            />
                             <Button type="primary" onClick={() => {search(searchValue)}}>Cari</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            scroll={{ x: 1300 }}
                            Row={5}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PROSES_VERIFIKASI);
