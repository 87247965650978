import moment from "moment-timezone";

export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, 'days').tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem('token'),
  app:{
    // title: "Ruby Chairani for DPR RI",
    // description:"Aplikasi pendataan pendukung Ruby Chairani",
    title: "Gerindra Dashboard Sumatera Selatan",
    description:"Aplikasi Data Pemilih Calon Legislatif Sumsel"
  },
  api: {
    // host:"http://localhost:4500",
    // host: "https://ruby-api.gawegawe.id",
    host: "https://gerindra.gawegawe.id",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: 'url(/img/background.png)',
    // logo: "/img/logo.png",
    // logo2: "/img/logo-colored.png",
    // favicon: "favicon.png",
    logo: "/img/logo_gerindra.png",
    logo2: "/img/logo_gerindra.png",
    favicon: "/img/logo_gerindra.png",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00"
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Surabah Dashboard",
  },
  navigation: {
    login: '/auth/login',
    register: '/auth/register',
    main: '/app',
    path: {
      login: 'login',
      register: 'register',
      forgot_password: 'forgot-password',
      activate: 'activate',
      resend:"resend",
      please: 'please',
      logout: "logout",
      dashboard: "/app/dashboard",
      pemilih: "/app/pemilih",
      caleg: "/app/caleg",
      dapil: "/app/dapil",
      kecamatan: "/app/kecamatan",
      semua_pemilih: "/app/semua-pemilih",
      kelurahan: "/app/kelurahan",
      kabupaten: "/app/kabupaten",
      sinkronisasi: "/app/sinkronisasi",
      semua_caleg: "/app/semua-caleg",
      provinsi: "/app/provinsi",
      tps: "/app/tps",
      analisa: "/app/analisa",
      tambah_pemilih: "/app/tambah-pemilih",
      detail_pemilih: "/app/detail-pemilih",
      detail_tps: "/app/detail-tps",
      detail_caleg: "/app/detail-caleg",
      detail_kecamatan: "/app/detail-kecamatan",
      detail_pengguna: "/app/detail-pengguna",
      detail_suara_caleg: "/app/detail-suara-caleg",
      detail_dapil: "/app/detail-dapil",
      detail_sinkronisasi: "/app/detail-sinkronisasi",
      detail_kelurahan: "/app/detail-kelurahan",
      detail_kabupaten: "/app/detail-kabupaten",
      detail_provinsi: "/app/detail-provinsi",
      pengguna: "/app/pengguna",
      profile: "/app/profile",
      settings: "/app/settings"
    }
  }
};
