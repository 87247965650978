import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllKelurahan = createAsyncThunk(
    'Kelurahan/fetchAllKelurahan',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.KELURAHAN)
            console.log(response)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllKelurahanQuery = createAsyncThunk(
    'Kelurahan/fetchAllKelurahan',
    async (payload, { rejectWithValue }) => {
        try {
            // ?skip=0&limit=10&id_kecamatan=1671010
            const response = await request('get', `${URLS.KELURAHAN}?limit=${payload.limit}&skip=${payload.page}&kecamatan=${payload.kecamatan}`)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchKelurahanByJenis = createAsyncThunk(
    'Kelurahan/fetchKelurahanByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KELURAHAN}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addKelurahan = createAsyncThunk(
    'Kelurahan/addKelurahan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.KELURAHAN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateKelurahan = createAsyncThunk(
    'Kelurahan/updateKelurahan',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.KELURAHAN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneKelurahan = createAsyncThunk(
    'Kelurahan/fetchOneKelurahan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KELURAHAN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const deleteKelurahan = createAsyncThunk(
    'Kelurahan/deleteKelurahan',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.KELURAHAN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const KelurahanSlice = createSlice({
    name: 'Kelurahan',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllKelurahan.pending, startLoadingQuery)
            .addCase(fetchAllKelurahan.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllKelurahan.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneKelurahan.pending, startLoadingQuery)
            .addCase(fetchOneKelurahan.rejected, stopLoadingQuery)
            .addCase(fetchOneKelurahan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateKelurahan.pending, startLoadingQuery)
            .addCase(updateKelurahan.rejected, stopLoadingQuery)
            .addCase(updateKelurahan.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchKelurahanByJenis.pending, startLoadingQuery)
            .addCase(fetchKelurahanByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchKelurahanByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteKelurahan.pending, startLoadingMutation)
            .addCase(deleteKelurahan.fulfilled, stopLoadingMutation)
            .addCase(deleteKelurahan.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = KelurahanSlice.actions;

export default KelurahanSlice.reducer;