import { Col, Row, message, Button } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PEMILIH } from '../pemilih/Pemilih';
import { fetchAllCaleg } from 'redux/features/caleg';
import { fetchAllPemilih, fetchAllPemilihCount } from 'redux/features/pemilih';

// total: total,
// terverifikasi: terverifikasi,
// tidak_terverifikasi: tidak_terverifikasi,
// nik_tidak_valid:nik_tidak_valid,
// sudah_dicek:sudah_dicek

export const AnnualStatisticData = [
  {
    title: 'Total Pemilih',
    value: 'Loading...',
  },
  {
    title: 'Pemilih Terverifikasi',
    value: 'Loading...',
  },
  {
    title: 'Pemilih Double Data',
    value: 'Loading...',
  }
]

export const DefaultDashboard = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [statistic, setStatistic] = useState({
    total: 0,
    terverifikasi: 0,
    tidak_terverifikasi: 0,
    nik_tidak_valid: 0,
    sudah_dicek: 0,
    data_double: 0
  })

  const getData = useCallback(async () => {
    try {
      await dispatch(fetchAllCaleg()).unwrap()
      await dispatch(fetchAllPemilih()).unwrap()
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getDashboard = async () => {
    const response = await dispatch(fetchAllPemilihCount()).unwrap()
    setStatistic({
      ...statistic,
      terverifikasi: response[0]?.terverifikasi,
      tidak_terverifikasi: response[0]?.tidak_terverifikasi,
      nik_tidak_valid: response[0]?.nik_tidak_valid,
      sudah_dicek:response[0]?.sudah_dicek,
      total: response[0].total,
      data_double:response[0].data_double
    })
  }

  useEffect(() => {
    getData()
    getDashboard()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Calon Pemilih`}
                value={statistic?.total || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Calon Pemilih Terdaftar`}
                value={statistic?.terverifikasi || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Calon Pemilih Sudah Dicek`}
                value={statistic?.sudah_dicek || 0}
              />
            </Col>
          </Row>
          <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Calon Pemilih Belum Terdaftar`}
                value={statistic?.tidak_terverifikasi - statistic?.nik_tidak_valid - statistic?.data_double || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Calon Pemilih NIK Tidak Valid`}
                value={statistic?.nik_tidak_valid || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Calon Pemilih Double`}
                value={statistic?.data_double || 0}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
            history.push({
              pathname: '/app/tambah-pemilih'
            })
          }} block>
            Tambah Data Pemilih
          </Button>
        </Col>
      </Row >
      <br />
      <PEMILIH desc={"Proses verifikasi perlu tindakan verif manual"} isTambah={false} title="Calon Pemilih Dalam Proses Verifikasi" terverifikasi={false} row={5} ></PEMILIH>
      <br />
      <PEMILIH terverifikasi={true} tanggal_input={moment().format("DD-MM-YYYY")} title={`Daftar calon pemilih ditambahkan tanggal ${moment().format("DD-MM-YYYY")}`} ></PEMILIH>
    </>
  )
}


export default withRouter(DefaultDashboard);
