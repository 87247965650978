import React from 'react';
import { Card } from 'antd'
import { Bar } from 'react-chartjs-2';


export const options = {
    responsive: true,
    legend: {
        display: false // This line removes the legend
    },
    plugins: {
        legend: {
            display:false,
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

export function BarChart(props) {

    const { labels, data, color } = props

    return (
        <Card>
            <h5 style={{ textAlign: 'center' }}>{props.title}</h5>
            <Bar height={props.height} options={options} data={{
                labels,
                datasets: [
                    {
                        label: false,
                        data: data,
                        backgroundColor: color,
                    },
                ],
            }} />
        </Card>
    )
}
