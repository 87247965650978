import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllPemilih = createAsyncThunk(
    'Pemilih/fetchAllPemilih',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllPemilihCount = createAsyncThunk(
    'Pemilih/fetchAllPemilihCount',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH + '/count')
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllPemilihQuery = createAsyncThunk(
    'Pemilih/fetchAllPemilihQuery',
    async (query, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH, query)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllPemilihQueryCount = createAsyncThunk(
    'Pemilih/fetchAllPemilihQueryCount',
    async (query, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH + `${query}`)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllPemilihQueryCountCity = createAsyncThunk(
    'Pemilih/fetchAllPemilihQueryCountCity',
    async (city, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH + `/count-query/1?kabupaten=` + city)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const verifPemilih = createAsyncThunk(
    'Pemilih/verifPemilih',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH + `/verif/` + id)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllPemilihCountAdmin = createAsyncThunk(
    'Pemilih/fetchAllPemilihCountAdmin',
    async (admin_id, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.PEMILIH + `/count-query/1?submitted_by=` + admin_id)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchPemilihByNIK = createAsyncThunk(
    'Pemilih/fetchPemilihByNIK',
    async (nik, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.PEMILIH}/nik/${nik}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addPemilih = createAsyncThunk(
    'Pemilih/addPemilih',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.PEMILIH, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addMany = createAsyncThunk(
    'Pemilih/addMany',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.PEMILIH + "/insert-many", payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addPemilihVerifikasi = createAsyncThunk(
    'Pemilih/addPemilihVerifikasi',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', `${URLS.PEMILIH}/verifikasi`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addPemilihManual = createAsyncThunk(
    'Pemilih/addPemilihManual',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', `${URLS.PEMILIH}/manual`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updatePemilih = createAsyncThunk(
    'Pemilih/updatePemilih',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.PEMILIH, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOnePemilih = createAsyncThunk(
    'Pemilih/fetchOnePemilih',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.PEMILIH}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deletePemilih = createAsyncThunk(
    'Pemilih/deletePemilih',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.PEMILIH}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const PemilihSlice = createSlice({
    name: 'Pemilih',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllPemilih.pending, startLoadingQuery)
            .addCase(fetchAllPemilih.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilih.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllPemilihCountAdmin.pending, startLoadingQuery)
            .addCase(fetchAllPemilihCountAdmin.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilihCountAdmin.rejected, stopLoadingQuery)
        builder
            .addCase(verifPemilih.pending, startLoadingQuery)
            .addCase(verifPemilih.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(verifPemilih.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllPemilihQueryCountCity.pending, startLoadingQuery)
            .addCase(fetchAllPemilihQueryCountCity.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilihQueryCountCity.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllPemilihQuery.pending, startLoadingQuery)
            .addCase(fetchAllPemilihQuery.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilihQuery.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllPemilihQueryCount.pending, startLoadingQuery)
            .addCase(fetchAllPemilihQueryCount.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilihQueryCount.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllPemilihCount.pending, startLoadingQuery)
            .addCase(fetchAllPemilihCount.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllPemilihCount.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOnePemilih.pending, startLoadingQuery)
            .addCase(fetchOnePemilih.rejected, stopLoadingQuery)
            .addCase(fetchOnePemilih.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updatePemilih.pending, startLoadingQuery)
            .addCase(updatePemilih.rejected, stopLoadingQuery)
            .addCase(updatePemilih.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(addMany.pending, startLoadingQuery)
            .addCase(addMany.rejected, stopLoadingQuery)
            .addCase(addMany.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(addPemilihVerifikasi.pending, startLoadingQuery)
            .addCase(addPemilihVerifikasi.rejected, stopLoadingQuery)
            .addCase(addPemilihVerifikasi.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(addPemilihManual.pending, startLoadingQuery)
            .addCase(addPemilihManual.rejected, stopLoadingQuery)
            .addCase(addPemilihManual.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchPemilihByNIK.pending, startLoadingQuery)
            .addCase(fetchPemilihByNIK.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchPemilihByNIK.rejected, stopLoadingQuery)
        builder
            .addCase(deletePemilih.pending, startLoadingMutation)
            .addCase(deletePemilih.fulfilled, stopLoadingMutation)
            .addCase(deletePemilih.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = PemilihSlice.actions;

export default PemilihSlice.reducer;