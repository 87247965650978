// CSVUpload.js
import { Button, Card, Col, Row, Select, message, Form } from 'antd';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import jwt_decode from 'jwt-decode'
import { fetchAllCaleg } from 'redux/features/caleg';

const Option = Select

const CSVUpload = () => {

  const dispatch = useDispatch()
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCaleg, setIdCaleg] = useState('')
  const [daftar_caleg, setDaftarCaleg] = useState([])
  const history = useHistory()

  const getCaleg = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllCaleg()).unwrap()
      setDaftarCaleg(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const onFileUploadSuccess = () => {
    message.success("Berhasil Upload Data Baru!")
    setLoading(false)
    history.push("/app/dashboard")
  }

  const onFileUploadFailure = () => {
    message.error("Gagal, coba lagi!")
    setLoading(false)
  }

  useEffect(() => {
    getCaleg()
  }, [])

  const onUpload = async () => {
    setLoading(true)
    if (!file) return;

    const formData = new FormData();
    let token = localStorage.getItem("token")
    let user = jwt_decode(token)
    formData.append('file', file);

    try {
      const response = await axios.post(`https://gerindra-bot.gawegawe.id/bulk-upload-manual?caleg_id=${selectedCaleg}&user_id=${user._id}&tanggal_input=${moment().format("DD-MM-YYYY")}`, formData, {
        timeout: 600000,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        onFileUploadSuccess(response.data);
      }
    } catch (error) {
      console.log(error)
      onFileUploadSuccess(error);
      // onFileUploadFailure(error);
    }
  };

  return (
    <Card>
      <p>Update dan tambah data pemilih sesuai dengan dapil yang dituju. <a target='_blank' href="https://docs.google.com/spreadsheets/d/1_13yfd792XswNJ0MMnihBow5puTbtu-StDVLvZT74jU/edit#gid=0">format upload bulk</a> download as CSV</p>
      <Form.Item
        label="Caleg"
        name="caleg"
      >
        <Select onSelect={(value) => {
          setIdCaleg(value)
        }}>
          {daftar_caleg.map(data => {
            return (
              <Option value={data._id}>
                {data.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <input type="file" accept=".csv" onChange={onFileChange} />
      <br />
      <br />
      <Button loading={loading} type='primary' style={{ width: "100%" }} onClick={onUpload}>Upload</Button>
    </Card>
  );
};

export default CSVUpload;