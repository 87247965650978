import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPemilih } from 'redux/features/pemilih';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const PEMILIH = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {
        list,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.tps || [])


    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllPemilih()).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'TPS',
            dataIndex: 'data_pemilih',
            render: (_, record) => {
                return (
                    moment(record.tanggalSurat).format("DD-MMM-YY")
                )
            }
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            render: (_, record) => {
                return (
                    <a target='_blank' href={record.externalFile}>Detail TPS</a>
                )
            },
        }
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Daftar TPS</h2>
                    <p>Semua tps yang telah terdata dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            Row={5}
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            pathname: '/app/detail-pemilih'
                        })
                    }} block>
                        Tambah Data Pemilih
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PEMILIH);
