import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import kelurahan, { fetchAllKecamatan, fetchAllKelurahanQuery } from 'redux/features/kelurahan';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const KELURAHAN = () => {

    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const [kecamatan, setKecamatan] = useState([])


    const getData = async (id_kecamatan) => {
        try {
            const kecamatan = await dispatch(fetchAllKelurahanQuery({
                id_kecamatan: id_kecamatan,
                limit: 30,
                page: 0
            })).unwrap()
            setKecamatan(kecamatan)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    useEffect(() => {
        const itemId = location.state?.id_kecamatan
        if (itemId) {
            getData(itemId)
        }
    }, [])

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id_kelurahan',
            key: 'id_kelurahan',
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Daftar TPS',
            dataIndex: 'tps',
            render: (_, record) => {
                return (
                    <div onClick={() => {
                        history.push("/app/tps", {
                            id_kelurahan: record.id_kelurahan
                        })
                    }}><a>Lihat</a></div>
                )
            },
        },
        {
            title: 'Update',
            dataIndex: 'update',
            render: (_, record) => {
                return (
                    <a onClick={() => {
                        history.push({
                            status: "DETAIL",
                            id_kelurahan: record.id_kelurahan,
                            id_kecamatan: location.state?.id_kecamatan,
                            pathname: '/app/detail-kelurahan'
                        })
                    }}>Update Data</a>
                )
            },
        }
    ];

    const onSearch = (value) => console.log(value);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Total {kecamatan.length} Kelurahan</h2>
                    <p>Semua data yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={kecamatan}
                            rowKey='id'
                            Row={5}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        history.push({
                            status: "ADD",
                            id_kecamatan: location.state?.id_kecamatan,
                            pathname: '/app/detail-kelurahan'
                        })
                    }} block>
                        Tambah Data Kelurahan
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(KELURAHAN);
