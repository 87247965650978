import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllPengguna } from 'redux/features/pengguna';

const selectStyle = {
    width: "100%",
    backgroundColor: "white"
};

export const PENGGUNA = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {
        list,
        filter: { q: searchTerm },
        loading: {
            query: loadingQuery,
            mutation: loadingMutation
        }
    } = useSelector(state => state.pengguna || [])


    const getData = useCallback(async () => {
        try {
            await dispatch(fetchAllPengguna()).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData()
    }, [])

    const tableColumns = [
        {
            title: 'Kode Admin',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                return record.email ? record.email : <div style={{ color: "red" }}>Belum Ada Data</div>
            },
        },
        // {
        //     title: 'Nama Lengkap',
        //     dataIndex: 'nama_lengkap',
        //     key: 'nama_lengkap',
        //     render: (_, record) => {
        //         return record.nama_lengkap ? record.nama_lengkap : <div style={{color:"red"}}>Belum Ada Data</div>
        //     },
        // },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',

        },
        {
            title: 'Calon Legislatif',
            dataIndex: 'caleg',
            key: 'caleg',
            render: (_, record) => {
                return (
                    <p>{record?.caleg ? record?.caleg : "Belum Dilakukan Pengaturan"}</p>
                )
            },

        },
        {
            title: 'Reset Password',
            dataIndex: 'reset_password',
            key: 'reset_password',
            render: (_, record) => {
                return (
                    <a onClick={() => { }} >Reset Password</a>
                )
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            render: (_, record) => {
                return (
                    <a onClick={() => {
                        history.push("/app/detail-pengguna", {
                            status: "DETAIL",
                            id: record._id
                        })
                    }}>Lihat</a>
                )
            },
        }
    ];

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Daftar Pengguna</h2>
                    <p>Semua surat yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        {/* <div style={{ display: "flex" }}>
                            <Search
                                placeholder="Cari Berdasarkan Perihal"
                                onSearch={onSearch}
                                style={{
                                    width: "49%",
                                    marginRight: "2%"
                                }}
                            />
                            <DatePicker
                                onChange={(value)=>{
                                    setStartDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Mulai' />
                            <DatePicker
                                onChange={(value)=>{
                                    setEndDate(moment(value).format("YYYY-MM-DD"))
                                }}
                            style={{
                                width: "24%",
                                marginRight: "2%"
                            }} placeholder='Tanggal Selesai' />
                             <Button type="primary" onClick={() => {getDataWithTanggal(startDateState,endDateState)}}>Cari</Button>
                        </div> */}
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            Row={5}
                            pagination={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" style={{ border: "0px" }} htmlType="submit" onClick={() => {
                        // history.push({
                        //     status: "ADD",
                        //     pathname: '/app/detail-pengguna'
                        // })
                    }} block>
                        Tambah Data Pengguna Hubungi 085899731884
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(PENGGUNA);
