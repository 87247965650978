import { Col, Row, message } from 'antd';
import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import { useEffect } from 'react';
import { addDapil, updateDapil, fetchOneDapil, deleteDapil } from 'redux/features/dapil';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Option = Select

export const DETAIL_DAPIL = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [id_kabupaten, setIdKabupaten] = useState('')
  const [id, setId] = useState('')

  const getDataById = async (id, id_kabupaten) => {
    try {
      const data = await dispatch(fetchOneDapil(id)).unwrap()
      form.setFieldsValue({
        ...data[0],
        id_kabupaten
      })
      setId(data[0]._id)
      setIdKabupaten(id_kabupaten)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      await dispatch(addDapil({
        ...values,
        id_kabupaten
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/dapil", {
        id_kabupaten: id_kabupaten
      })
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateDapil({
        ...values,
        id: id
      }))
      message.success("Berhasil diupdate silahkan tunggu hasil review!")
      history.push("/app/dapil", {
        id_kabupaten: id_kabupaten
      })
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deleteDapil(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/dapil", {
        id_kabupaten: id_kabupaten
      })
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id_dapil
    setIdKabupaten(location?.state?.id_kabupaten)
    if (itemId) {
      setId(itemId)
      getDataById(itemId, location.state.id_kabupaten)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>{id ? 'Detail' : 'Tambah'} Dapil Id Kabupaten: {id_kabupaten}</h2>
          <p>Update dan tambah data Dapil sesuai dengan dapil yang dituju.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >

              <Form.Item
                label="Nama"
                name="name"
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  {id ? 'Update' : 'Tambah'} Data
                </Button>
              </Form.Item>

            </Form>
          </Card>
          {id && (
            <Button onClick={() => { deleteData(id) }} type="primary" htmlType="submit" style={{ width: "100%" }}>
              Delete Data
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default DETAIL_DAPIL