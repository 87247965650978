import { Button, Card, Col, Row, Table, message, Input, Select } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import csvDownload from 'json-to-csv-export'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { withRouter } from 'react-router-dom';
import { fetchAllPemilih, fetchAllPemilihQueryCountCity, fetchPemilihByNIK, fetchAllPemilihQuery, fetchAllPemilihCountAdmin } from 'redux/features/pemilih';
import { fetchAllKabupaten, fetchOneKabupaten } from 'redux/features/kabupaten';
import { fetchAllPengguna, fetchOnePengguna } from 'redux/features/pengguna';
import { fetchAllKecamatanQuery, fetchOneKecamatan } from 'redux/features/kecamatan';
import GroupChart from './GroupChart';

const Option = Select

export const PEMILIH = () => {
    const history = useHistory()
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('')
    const [list, setList] = useState([])
    const [loading,setLoading] = useState(false)
    const location = useLocation()
    const [kabupaten, setKabupaten] = useState([])
    const [selectedKabupaten, setSelectedKabupaten] = useState(null)
    const [selectedKabupatenName, setSelectedKabupatenName] = useState(null)
    const [kecamatan, setKecamatan] = useState([])
    const [selectedKecamatan, setSelectedKecamatan] = useState(null)
    const [selectedKecamatanName, setSelectedKecamatanName] = useState(null)
    const [user, setUser] = useState([])
    const [forDownload, setForDownload] = useState([])
    const [kabupatenPemilih, setKabupatenPemilih] = useState([])
    const [pemilihPerCityCount, setPemilihPerCityCount] = useState([])
    const [submitted_by, setSubmittedBy] = useState([])
    const [adminList, setAdminList] = useState([])
    const [pemilihAdminList, setPemilihAdminList] = useState([])

    const getDataKabupaten = async () => {
        try {
            const response = await dispatch(fetchAllKabupaten()).unwrap()
            response.map(city => {
                getDataPemilih(city._id)
            })
            setKabupaten(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getDataPemilih = async (city_id) => {
        try {
            const response = await dispatch(fetchAllPemilihQueryCountCity(city_id)).unwrap()
            if (response) {
                setKabupatenPemilih(prevState => [...prevState, response.kabupaten])
                setPemilihPerCityCount(prevState => [...prevState, response.total_pemilih])
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getDataPemilihByAdmin = async (admin_id) => {
        try {
            const response = await dispatch(fetchAllPemilihCountAdmin(admin_id)).unwrap()
            if (response) {
                setAdminList(prevState => [...prevState, response.submitted_by])
                setPemilihAdminList(prevState => [...prevState, response.total_pemilih])
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getDataKecamatan = async (value) => {
        try {
            const response = await dispatch(fetchAllKecamatanQuery({
                limit: 100,
                page: 0,
                kabupaten: value
            })).unwrap()
            setKecamatan(response || [])
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const getDataAdmin = useCallback(async () => {
        try {
            const response = await dispatch(fetchAllPengguna()).unwrap()
            response.map(user => {
                // console.log(user)
                getDataPemilihByAdmin(user._id)
            })
            setUser(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    const getDataCaleg = useCallback(async (id) => {
        try {
            const response = await dispatch(fetchAllPemilihQuery({
                caleg:id,
                isVerified:true,
                limit:10000
            })).unwrap()
            setList(response)
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        const itemId = location.state?.id
        getDataKabupaten()
        getDataAdmin()
        getDataCaleg(itemId)
    }, [])

    const tableColumns = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: 'nik',
        },
        {
            title: 'Kelurahan',
            dataIndex: 'kelurahan',
            key: 'kelurahan',
            render: (_, record) => {
                return (
                    <div>{record.kelurahan?.name}</div>
                )
            }
        },
        {
            title: 'Kecamatan',
            dataIndex: 'kecamatan',
            key: 'kecamatan',
            render: (_, record) => {
                return (
                    <div>{record.kecamatan?.name}</div>
                )
            },
        },
        {
            title: 'Kabupaten/Kota',
            dataIndex: 'kabupaten',
            key: 'kabupaten',
            render: (_, record) => {
                return (
                    <div>{record.kabupaten?.name}</div>
                )
            },
        },
        {
            title: 'Tanggal Input',
            dataIndex: 'tanggal_input',
            key: 'tanggal_input',
            render: (_, record) => {
                return (
                    <div>{record?.tanggal_input}</div>
                )
            },
        },
        {
            title: 'TPS',
            dataIndex: 'tps',
            key: 'tps',
            render: (_, record) => {
                return (
                    <div>{record?.tps}</div>
                )
            },
        },
        {
            title: 'Verifikasi DPT',
            dataIndex: 'tps',
            render: (_, record) => {
                if (record.isVerified) {
                    return (
                        <div style={{ color: "green" }} >Terverifikasi</div>
                    )
                } else {
                    return (
                        <div style={{ color: "red" }} >Belum Terverifikasi</div>
                    )
                }
            },
        },
        // {
        //     title: 'Calon Legislatif',
        //     dataIndex: 'caleg',
        //     key:'caleg',
        //     render:(_,record)=>{
        //         return(
        //             <>{record.caleg ?  <div style={{color:'green'}}><b>{record.caleg?.name}</b></div> : <div style={{color:'red'}}>{"Belum Ditentukan"}</div>}</>
        //         )
        //     }
        // },
        {
            title: 'Diinput Oleh',
            dataIndex: 'submitted_by',
            key: 'submitted_by',
            render: (_, record) => {
                return (
                    <>{record.submitted_by ? <div style={{ color: 'green' }}><b>{record.submitted_by?.username}</b></div> : <div style={{ color: 'red' }}>{"Belum Ditentukan"}</div>}</>
                )
            }
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            render: (_, record) => {
                return (
                    <a onClick={() => {
                        history.push("/app/detail-pemilih", {
                            status: "DETAIL",
                            id: record._id
                        })
                    }}>Lihat</a>
                )
            },
        }
    ];

    const onSearch = (value) => {
        setSearchValue(value.target.value)
        
    };

    let convertToDownload = (response) =>{
        let results = []
        response.map(pemilih=>{
            results = [...results,{
                ...pemilih,
                kabupaten:pemilih.kabupaten.name,
                kecamatan:pemilih.kecamatan.name,
                kelurahan:pemilih.kelurahan.name,
                caleg:pemilih.caleg.name,
                submitted_by:pemilih.submitted_by.username
            }]
        })
        return results
    }

    const search = async () => {
        try {
            if (searchValue !== '') {
                const response = await dispatch(fetchPemilihByNIK(searchValue)).unwrap()
                setList(response)
                setForDownload(convertToDownload(response))
            } else if (selectedKabupaten) {
                if (!selectedKecamatan) {
                    const response = await dispatch(fetchAllPemilihQuery({
                        kabupaten: selectedKabupaten
                    })).unwrap()
                    setList(response)
                    setForDownload(convertToDownload(response))
                } else {
                    const response = await dispatch(fetchAllPemilihQuery({
                        kecamatan: selectedKecamatan
                    })).unwrap()
                    setList(response)
                    setForDownload(convertToDownload(response))
                }
            }
            else if (submitted_by) {
                const response = await dispatch(fetchAllPemilihQuery({
                    submitted_by: submitted_by
                })).unwrap()
                setList(response)
                setForDownload(convertToDownload(response))
            }
            else {
                const response = await dispatch(fetchAllPemilih()).unwrap()
                setList(response)
                setForDownload(convertToDownload(response))
            }
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }

    const downloadToCSV = async () =>{
        setLoading(true)
        await csvDownload({
            data: forDownload,
            filename: 'hasil-analisa',
            delimiter: ',',
            
          })
        setLoading(false)
    }

    const cariBerdasarkanKabupaten = async (value) => {
        setSelectedKabupaten(value)
        getDataKecamatan(value)
    }

    const cariBerdasarkanKecamatan = async (value) => {
        setSelectedKecamatan(value)
    }

    const cariBerdasarkanAdmin = async (value) => {
        setSubmittedBy(value)
    }

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Cek Pemilih Per Caleg</h2>
                    <p>Semua surat yang telah masuk dan dibutuhkan tindakan lanjut</p>
                </Col>
            </Row>
            {kabupatenPemilih.length > 0 && pemilihPerCityCount.length > 0 && (
                <GroupChart admin={{ adminList, pemilihAdminList }} pemilihPerCity={{ kabupatenPemilih, pemilihPerCityCount }} ></GroupChart>
            )}
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <StatisticWidget
                                style={{ textAlign: "center" }}
                                title={`Total Calon Pemilih Terhitung`}
                                value={list.length || "Tidak Ada"}
                            />
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                            <StatisticWidget
                                style={{ textAlign: "center" }}
                                title={`Kabupaten Terpilih`}
                                value={selectedKabupatenName || "Tidak Ada"}
                            />
                        </Col> */}
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
                            <StatisticWidget
                                style={{ textAlign: "center" }}
                                title={`Kecamatan Terpilih`}
                                value={selectedKecamatanName || "Tidak Ada"}
                            />
                        </Col> */}
                    </Row>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <div style={{ display: "flex" }}>
                            <Select
                                placeholder="Cari Berdasarkan Kabupaten"
                                onChange={cariBerdasarkanKabupaten}
                                style={{
                                    width: "20%",
                                    marginRight: "1%"
                                }}
                            >
                                {kabupaten.map(doc => {
                                    return (
                                        <Option value={doc._id} >{doc.name}</Option>
                                    )
                                })}
                            </Select>
                            <Select
                                placeholder="Cari Berdasarkan Kecamatan"
                                onChange={cariBerdasarkanKecamatan}
                                disabled={kecamatan.length > 0 ? false : true}
                                style={{
                                    width: "20%",
                                    marginRight: "1%"
                                }}
                            >
                                {kecamatan.map(doc => {
                                    return (
                                        <Option value={doc._id} >{doc.name}</Option>
                                    )
                                })}
                            </Select>
                            <Select
                                placeholder="Cari Berdasarkan Admin"
                                onChange={cariBerdasarkanAdmin}
                                style={{
                                    width: "20%",
                                    marginRight: "1%"
                                }}
                            >
                                {user.map(doc => {
                                    return (
                                        <Option value={doc._id} >{doc.username}</Option>
                                    )
                                })}
                            </Select>
                            <Button style={{ width: "20%", marginRight: "1%" }} type="primary" onClick={() => { search(searchValue) }}>Cari</Button>
                            <Button style={{ width: "20%", marginRight: "1%" }} type="primary" onClick={() => {
                                setList([])
                                setSelectedKabupaten(null)
                                setSelectedKecamatan(null)
                            }}>Reset</Button>
                        </div>
                        <Table
                            className="no-border-last"
                            columns={tableColumns}
                            dataSource={list}
                            rowKey='id'
                            scroll={{ x: 1300 }}
                            Row={5}
                            pagination={true}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Button loading={loading} style={{ width: "100%" }} type="primary" onClick={downloadToCSV} >Download to CSV</Button>
            </Row>
        </>
    )
}


export default withRouter(PEMILIH);
