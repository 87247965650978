import React from 'react';
import { Card } from 'antd'
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

const labels = ['Abdul Rais', 'Muhammad Ridho', 'Hari Apriyansyah', 'Rata - Rata'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Pemilih',
            data: [5400, 5400, 7500, 3200],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
    ],
};

export function TopCaleg() {
    return <Card>
        <h4>3 Caleg DPRD Pemilih Terbanyak</h4>
        <Bar height={120} options={options} data={data} />
    </Card>
}
