import { Col, Row } from 'antd';
import { Button, Card, Form, Input, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Select } from 'antd';
import { fetchOnePengguna, addPengguna, updatePengguna, deletePengguna } from 'redux/features/pengguna';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCaleg } from 'redux/features/caleg';

const Option = Select

const selectStyle = {
  width: "100%",
  backgroundColor: "white"
};

const rules = [
  {
    required: true,
    message: 'Wajib Diisi!',
  }
]

const options = [
  {
    value: 'admin',
    label: 'admin',
  },
  {
    value: 'koordinator',
    label: 'koordinator',
  },
  {
    value: 'user',
    label: 'user',
  }
]

export const DETAIL_PENGGUNA = () => {

  const location = useLocation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()
  const [id_caleg, setIdCaleg] = useState(null)
  const [daftar_caleg, setDaftarCaleg] = useState([])
  const [id, setId] = useState()

  const getCaleg = async () => {
    try {
      const response = await dispatch(fetchAllCaleg()).unwrap()
      setDaftarCaleg(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getData = async (id) => {
    const response = await dispatch(fetchOnePengguna(id)).unwrap()
    form.setFieldsValue(response[0])
  }

  const update = async (values) => {
    try {
      await dispatch(updatePengguna({
        id: id,
        caleg:id_caleg,
        ...values
      }))
      message.success("Berhasil diupdate silahkan tunggu hasil review!")
      history.push("/app/pengguna")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const tambah = async (values) => {
    try {
      await dispatch(addPengguna({
        ...values,
        caleg:id_caleg,
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pengguna")
    } catch (err) {
      console.log(err)
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      update(values)
    } else {
      tambah(values)
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    getCaleg()
    if (itemId) {
      getData(itemId)
      setId(itemId)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Atur Pengguna</h2>
          <p>Atur pengguna dan privilege pengguna</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={rules}
              >
                <Select
                  defaultValue="User"
                  style={selectStyle}
                  onChange={handleChange}
                  options={options}
                />
              </Form.Item>

             <Form.Item
              label="Caleg"
              name="caleg"
            >
              <Select onSelect={(value) => {
                setIdCaleg(value)
              }}>
                {daftar_caleg.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  {id ? 'Update Pengguna' : "Tambah Pengguna"}
                </Button>
              </Form.Item>
              {id && (
                <Button type="primary" onClick={async () => {
                  await dispatch(deletePengguna(id))
                  history.push("/app/pengguna")
                }} style={{ width: "100%" }}>
                  Delete Pengguna
                </Button>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default DETAIL_PENGGUNA