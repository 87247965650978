import { Col, Row, message } from 'antd';
import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { Select } from 'antd';
import jwt_decode from 'jwt-decode'
import { useEffect, useCallback } from 'react';
import { addPemilihManual, updatePemilih, fetchOnePemilih, deletePemilih } from 'redux/features/pemilih';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchAllKabupaten, fetchAllKabupatenQuery } from 'redux/features/kabupaten';
import { fetchAllKecamatanQuery } from 'redux/features/kecamatan';
import { fetchAllKelurahanQuery } from 'redux/features/kelurahan';
import { fetchAllCaleg } from 'redux/features/caleg';

const Option = Select

export const DETAIL_MANUAL = () => {

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const [jabatan, setJabatan] = useState('Belum Ditentukan')
  const [tipe, setTipe] = useState('Belum Terverifikasi')
  const [id_kabupaten, setIdKabupaten] = useState(null)
  const [id_kecamatan, setIdKecamatan] = useState(null)
  const [id_kelurahan, setIdKelurahan] = useState(null)
  const [id_tps, setIdTPS] = useState(null)
  const [id_caleg, setIdCaleg] = useState(null)
  const [daftar_kabupaten, setDaftarKabupaten] = useState([])
  const [daftar_kecamatan, setDaftarKecamatan] = useState([])
  const [daftar_kelurahan, setDaftarKelurahan] = useState([])
  const [daftar_tps, setDaftarTPS] = useState([])
  const [daftar_caleg, setDaftarCaleg] = useState([])
  const [id, setId] = useState('')

  const {
    list,
    filter: { q: searchTerm },
    loading: {
      query: loadingQuery,
      mutation: loadingMutation
    }
  } = useSelector(state => state.kabupaten || [])

  const getData = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllKabupatenQuery(0, 17)).unwrap()
      setDaftarKabupaten(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getCaleg = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllCaleg()).unwrap()
      setDaftarCaleg(response)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [dispatch])

  const getKecamatan = async (value) => {
    try {
      const response = await dispatch(fetchAllKecamatanQuery({
        limit: 100,
        page: 0,
        kabupaten: value
      })).unwrap()
      setDaftarKecamatan(response)
      setIdKecamatan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getKelurahan = async (value) => {
    try {
      const response = await dispatch(fetchAllKelurahanQuery({
        limit: 100,
        page: 0,
        kecamatan: value
      })).unwrap()
      setDaftarKelurahan(response)
      setIdKelurahan(value)
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getDataById = async (id) => {
    try {
      await dispatch(fetchAllKabupaten())
      const data = await dispatch(fetchOnePemilih(id)).unwrap()
      form.setFieldsValue(data[0])
      setJabatan(data[0].jabatan)
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const onFinish = async (values) => {
    if (id) {
      updateData({
        ...values,
        id
      })
    } else {
      addData(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addData = async (values) => {
    try {
      let token = localStorage.getItem("token")
      let user = jwt_decode(token)
      if (values.nik.length === 16) {
        message.error("NIK harus 16 digit!")
      }else{
        await dispatch(addPemilihManual({
          ...values,
          name: values.name.toUpperCase(),
          submitted_by: user._id,
          isVerified: false
        }))
        message.success("Berhasil dibuat silahkan tunggu hasil review!")
        history.push("/app/pemilih")
      }
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updatePemilih({
        ...values,
        jabatan
      }))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/pemilih")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  const deleteData = async () => {
    try {
      await dispatch(deletePemilih(id))
      message.success("Berhasil dibuat silahkan tunggu hasil review!")
      history.push("/app/caleg")
    } catch (err) {
      message.error("Ada yang salah! Coba lagi")
    }
  }

  useEffect(() => {
    const itemId = location.state?.id
    getData()
    getCaleg()
    if (itemId) {
      setId(itemId)
      getDataById(itemId)
    }
  }, [])

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Card>
          <h4>Manual Tanpa Verifikasi</h4>
          <p>Upload data dan perlu diverifikasi secara manual pada saat DPT Online down</p>
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >

            <Form.Item
              label="Nama"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Masukan data nama!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="NIK"
              name="nik"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kabupaten"
              name="kabupaten"
            >
              <Select onSelect={(value) => {
                setIdKabupaten(value)
                getKecamatan(value)
              }}>
                {daftar_kabupaten.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            {id_kabupaten && (
              <Form.Item
                label="Kecamatan"
                name="kecamatan"
              >
                <Select onSelect={(value) => {
                  setIdKecamatan(value)
                  getKelurahan(value)
                }}>
                  {daftar_kecamatan.map(data => {
                    return (
                      <Option value={data?._id}>
                        {data?.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )}
            {id_kecamatan && (
              <Form.Item
                label="Kelurahan"
                name="kelurahan"
              >
                <Select onSelect={(value) => {
                  setIdKelurahan(value)
                  getKelurahan(value)
                }}>
                  {daftar_kelurahan.map(data => {
                    return (
                      <Option value={data?._id}>
                        {data?.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="TPS"
              name="tps"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Caleg"
              name="caleg"
            >
              <Select onSelect={(value) => {
                setIdCaleg(value)
              }}>
                {daftar_caleg.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                Tambah Data
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </>
  )
}

export default DETAIL_MANUAL