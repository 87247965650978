import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllKabupaten = createAsyncThunk(
    'Kabupaten/fetchAllKabupaten',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.KABUPATEN)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllKabupatenQuery = createAsyncThunk(
    'Kelurahan/fetchAllKabupaten',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KABUPATEN}`)
            return response.data.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const fetchKabupatenByJenis = createAsyncThunk(
    'Kabupaten/fetchKabupatenByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KABUPATEN}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addKabupaten = createAsyncThunk(
    'Kabupaten/addKabupaten',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.KABUPATEN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateKabupaten = createAsyncThunk(
    'Kabupaten/updateKabupaten',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', URLS.KABUPATEN, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneKabupaten = createAsyncThunk(
    'Kabupaten/fetchOneKabupaten',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.KABUPATEN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteKabupaten = createAsyncThunk(
    'Kabupaten/deleteKabupaten',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.KABUPATEN}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const KabupatenSlice = createSlice({
    name: 'Kabupaten',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllKabupaten.pending, startLoadingQuery)
            .addCase(fetchAllKabupaten.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllKabupatenQuery.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllKabupatenQuery.pending, startLoadingQuery)
            .addCase(fetchAllKabupatenQuery.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllKabupaten.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneKabupaten.pending, startLoadingQuery)
            .addCase(fetchOneKabupaten.rejected, stopLoadingQuery)
            .addCase(fetchOneKabupaten.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateKabupaten.pending, startLoadingQuery)
            .addCase(updateKabupaten.rejected, stopLoadingQuery)
            .addCase(updateKabupaten.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchKabupatenByJenis.pending, startLoadingQuery)
            .addCase(fetchKabupatenByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchKabupatenByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteKabupaten.pending, startLoadingMutation)
            .addCase(deleteKabupaten.fulfilled, stopLoadingMutation)
            .addCase(deleteKabupaten.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = KabupatenSlice.actions;

export default KabupatenSlice.reducer;